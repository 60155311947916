import { useEffect } from 'react';

const useHeadScript = (src: string, environment: string) => {
  const enabled = environment !== 'production';

  useEffect(() => {
    let scriptElement: HTMLScriptElement | null = null;

    if (src && enabled) {
      scriptElement = document.createElement('script');
      scriptElement.setAttribute('src', src);
      document.head.appendChild(scriptElement);
    }

    return () => {
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, [src, enabled]);
};

export { useHeadScript };
