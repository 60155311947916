import queryString from 'query-string';
import { NgrokConfig } from '../components/DemoForm/DemoForm';

function isValidHttpUrl(str: string) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const generateRedirectURL = (
  baseUrl: string,
  token: string,
  flagOverrides: Record<string, unknown>,
  ngrok: NgrokConfig,
  directURL?: string | false,
  language?: string
) => {
  const qs = queryString.stringify({...flagOverrides, lang: language});
  const path = `v/${token}?${qs}`;

  if (ngrok.enabled) {
    if (!isValidHttpUrl(ngrok.frontBaseUrl)) {
      throw new Error('Ngrok URL should be valid HTTP URL, e.g. https://validurl.com');
    }
    return `${ngrok.frontBaseUrl}/${path}`;
  }
  if (directURL) {
    return `${directURL}/${path}`;
  }

  return `${baseUrl.replace('3000', '3002')}/${path}`;
};
