export interface Quote {
    text: string;
    author: string;
    addedOn: string;
    addedBy: string;
}

export const quotes: Array<Quote> = [
    {
        "text": "The only way of discovering the limits of the possible is to venture a little way past them into the impossible.",
        "author": "Arthur C. Clarke",
        "addedOn": "2021-09-08",
        "addedBy": "@madisp",
    },
    {
        "text": "I must not fear. Fear is the mind-killer. Fear is the little-death that brings total obliteration. I will face my fear. I will permit it to pass over me and through me. And when it has gone past I will turn the inner eye to see its path. Where the fear has gone there will be nothing. Only I will remain",
        "author": "Frank Herbert",
        "addedOn": "2021-09-09",
        "addedBy": "@dkorobtsov",
    },
    {
        "text": "Sometimes life is like this dark tunnel. You can't always see the light at the end of the tunnel, but if you just keep moving... you will come to a better place.",
        "author": "Uncle Iroh",
        "addedOn": "2021-09-09",
        "addedBy": "@karenminskiveriff",
    },
];
