import React, { Dispatch, SetStateAction, useState } from 'react';
import './FeatureFlagOverrides.css';
import { TextField, Label, Tooltip, Checkbox, Collapsible, Heading, IconInfo } from '@veriff/genoma';
import FlagOverrideSelector from '../ui/FlagOverrideSelector';

import { FeatureFlag } from '../../api/featureFlags';
import { FlagOverrides, SavedCategory } from '../DemoForm/DemoForm';
import FeatureFlagCategory from '../ui/FeatureFlagCategory';
import EnabledFlags from '../ui/EnabledFlags';

interface Props {
  featureFlags: FeatureFlag[];
  selectedFlags: FlagOverrides;
  setSelectedFlags: Dispatch<SetStateAction<FlagOverrides>>;
  savedCategories: SavedCategory[];
  setSavedCategories: Dispatch<SetStateAction<SavedCategory[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
}

const sessionOverrides = ['verification_status', 'document_country', 'document_type', 'isTest'];

const FeatureFlagOverrides: React.FC<Props> = ({
  savedCategories,
  setSavedCategories,
  featureFlags,
  selectedFlags,
  setSelectedFlags,
  formik,
}) => {
  const [isAllFlagsOpen, setAllFlagsOpen] = useState(true);
  const [isAdditionalDataOpen, setAdditionalDataOpen] = useState(false);
  const [isSessionOverridesOpen, setSessionOverridesOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');

  const handleSaveCategory = () => {
    setSavedCategories([
      ...savedCategories,
      {
        name: newCategoryName,
        flags: selectedFlags,
      },
    ]);
    setNewCategoryName('');
  };

  const handleRemoveCategory = (name: string) => {
    setSavedCategories(savedCategories.filter(category => category.name !== name));
  };

  const handleSelectCategory = (name: string) => {
    const category = savedCategories.find(cat => cat.name === name);
    if (category) {
      setSelectedFlags({ ...selectedFlags, ...category.flags });
    }
  };

  const handleClearAll = () => {
    setSelectedFlags({});
  };

  return (
    <React.Fragment>
      <section>
        <Heading level={3} className="no-margin">
          <span className="m-r">Feature Flags</span>
        </Heading>
        <div className="divider" />
      </section>
      <section className="collapsible-group categories-group">
        <div className="grid-panel flex-column">
          <Heading level={3} className="title">
            {' '}
            Favourites{' '}
          </Heading>
          {savedCategories.map(category => (
            <FeatureFlagCategory
              key={category.name}
              category={category}
              removeCategory={handleRemoveCategory}
              selectCategory={handleSelectCategory}
            />
          ))}
        </div>
        <EnabledFlags
          featureFlags={featureFlags.filter(flag => selectedFlags[flag.name] !== undefined)}
          selectedFlags={selectedFlags}
          setSelectedFlags={setSelectedFlags}
          clearSelected={handleClearAll}
          saveSelection={handleSaveCategory}
          onNewCategoryNameChanged={setNewCategoryName}
          newCategoryName={newCategoryName}
        />
      </section>
      <section className="all-flags-section">
        <span>
          <Heading level={3}>
            <span className="m-r">All flags & Additional data</span>
          </Heading>
          <TextField placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} className="search" />
        </span>
        <section>
          {search !== '' ? (
            <div>
              {featureFlags
                .filter(flag => flag.name.toLowerCase().includes(search.toLowerCase()))
                .map(flag => (
                  <FlagOverrideSelector
                    key={flag.name}
                    flag={flag}
                    value={selectedFlags[flag.name]}
                    onSetValue={value => {
                      setSelectedFlags({ ...selectedFlags, [flag.name]: value });
                    }}
                  />
                ))}
            </div>
          ) : (
            <Collapsible
              title="All flags"
              onToggle={isOpen => setAllFlagsOpen(isOpen)}
              open={isAllFlagsOpen}
              className="collapsible-flags all-flags"
            >
              <div className="grid-panel">
                {featureFlags
                  .filter(flag => !sessionOverrides.includes(flag.name))
                  .map(flag => (
                    <FlagOverrideSelector
                      key={flag.name}
                      flag={flag}
                      value={selectedFlags[flag.name]}
                      onSetValue={value => {
                        setSelectedFlags({ ...selectedFlags, [flag.name]: value });
                      }}
                    />
                  ))}
              </div>
            </Collapsible>
          )}
        </section>
        <div className="collapsible-group">
          <Collapsible
            title="Session overrides"
            onToggle={isOpen => setSessionOverridesOpen(isOpen)}
            open={isSessionOverridesOpen}
            className="collapsible-flags"
          >
            <div className="grid-panel">
              {featureFlags
                .filter(flag => sessionOverrides.includes(flag.name))
                .map(flag => (
                  <FlagOverrideSelector
                    key={flag.name}
                    flag={flag}
                    value={selectedFlags[flag.name]}
                    onSetValue={value => {
                      setSelectedFlags({ ...selectedFlags, [flag.name]: value });
                    }}
                  />
                ))}
            </div>
          </Collapsible>
          <Collapsible
            title="Additional data"
            onToggle={isOpen => setAdditionalDataOpen(isOpen)}
            open={isAdditionalDataOpen}
            className="collapsible-flags"
          >
            <div className="flex items-center">
              <Label label="isTest">
                <Checkbox
                  name="isTest"
                  checked={formik.values.additionalData?.isTest}
                  onChange={() =>
                    formik.setFieldValue('additionalData', {
                      ...formik.values.additionalData,
                      isTest: !formik.values.additionalData?.isTest,
                    })
                  }
                />
                <Tooltip label="Disables/enables inflow feedback for the session. Used only for E2E testing.">
                  <IconInfo className="m-l" />
                </Tooltip>
              </Label>
            </div>
          </Collapsible>
        </div>
      </section>
    </React.Fragment>
  );
};

export default FeatureFlagOverrides;
