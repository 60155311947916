export interface VendorIntegration {
  name: string;
  value: string;
}

export const integrations: VendorIntegration[] = [
  { name: 'Veriff Staging Demo', value: '' },
  {
    name: 'Veriff Demo - minimal profile',
    value: 'ade43305-5bae-4ce4-89a6-aa12f20ba36c',
  },
  {
    name: 'Proof of Address Extraction',
    value: 'b318e93d-ac86-4cec-b6f6-e3d57448e6a3',
  },
  {
    name: 'Proof of Address Manual Extraction',
    value: 'f15d6982-0d0b-4d73-85e5-6822dfba0130',
  },
  {
    name: 'Proof of Address Manual Extraction with Specimen',
    value: '3e136e87-ecf2-497c-9195-568eda8c3d32',
  },
  {
    name: 'Proof of Address Capture',
    value: 'a83a47bd-725a-4e33-9f07-8c27782b87b0',
  },
  {
    name: 'End User Aadhaar Integration',
    value: '37c28fef-5ad7-450f-806d-3d1057967495',
  },
  {
    name: 'Inflow Integration',
    value: '16fdb834-e85d-4782-9c31-09ec52087fba',
  },
  {
    name: 'End User ge Manual Integration',
    value: '6928a727-5c46-4f61-a079-385573905da0',
  },
  {
    name: 'End User ge Live Integration',
    value: 'fd2697ff-77e4-4d27-a3d0-3f5985ccbcfd',
  },
  {
    name: 'User Research',
    value: '39a627dd-d3cf-4f9f-bc9b-c6605aa28191',
  },
  {
    name: 'End User Other Doc Support',
    value: 'e3f33f41-1732-4738-abca-f9465a1a26e5',
  },
  {
    name: 'Partial Verification',
    value: 'da8b532a-4817-4296-944e-1c14a13a3d32',
  },
  {
    name: 'Verification Tools priority test',
    value: '5b8fb671-11bb-4a36-a7f7-51eb2e66c3bb',
  },
  {
    name: 'Verification Tools',
    value: 'bb02814d-0b51-4972-9e39-5c61301abd67',
  },
  {
    name: 'Biometric IDV',
    value: '4cd230c7-d4fc-4ec7-aad7-cf7579e75683',
  },
  {
    name: 'Biometric face match',
    value: 'cbbb2dae-824c-4d30-9800-8c9e9edfb411',
  },
  {
    name: 'Right to Drive - short',
    value: '251e336d-4f63-4b9b-9d6a-509f930e4146',
  },
  {
    name: 'Right to Drive - long',
    value: '87f0cc1a-c89f-48e9-94b1-c5c8a56143e4',
  },
  {
    name: 'Proof of Address - short flow',
    value: 'd0123700-cff5-4486-aa70-9c6a6f5da8c4',
  },
  {
    name: 'Proof of Address - long flow',
    value: 'dc1f0df0-52cb-4cbe-8930-1378246412ce',
  },
  {
    name: 'Bogdan\'s E2E',
    value: '6bf6f7ee-2b47-470c-8b43-33a88328c0ab',
  },
  {
    name: 'Bogdan\'s POA',
    value: '5ed2109d-caf3-4a30-87f3-a23170b6ae67',
  },
  {
    name: 'Bogdan\'s B_CATEGORY',
    value: '7ae60a73-f7c5-4716-b53a-98653b66c5ca',
  },
  {
    name: 'Bogdan\'s AGE_ESTIMATION',
    value: 'e2c96b72-7c96-41b7-b2fa-6c4b40716e1e',
  },
  {
    name: 'Tauno Dev',
    value: '470caf8e-ca10-4d6d-9f76-1e6fd8fa8493',
  },
  {
    name: 'Francesco Dev',
    value: '61609169-42be-4a4f-8f14-6017934a7664',
  },
  {
    name: 'Andrés Dev',
    value: '32c5af72-c35c-44f4-801a-1dbc7313b54a'
  }
];
