import React, { useContext, useMemo } from 'react';
import { Button, Stack } from '@veriff/genoma';
import { GoogleReCaptchaContext, ReCaptchaElement } from '../../utils/google-recaptcha-provider';

interface IVeriffMeButton {
  captchaEnabled?: boolean;
  loading: boolean;
  submitForm: () => void;
}

export default function VeriffMeButton({ captchaEnabled, loading, submitForm }: IVeriffMeButton) {
  const captcha = useContext(GoogleReCaptchaContext);
  const type = captchaEnabled ? 'button' : 'submit';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isLoading = useMemo(() => {
    if (!captchaEnabled) {
      return loading;
    }

    return !captcha || loading;
  }, [captchaEnabled, loading, captcha]);

  const onClick = useMemo(() => {
    if (!captchaEnabled || (captchaEnabled && !captcha)) {
      return undefined;
    }

    return submitForm;
  }, [captchaEnabled, captcha, submitForm]);

  return (
    <Stack align="center" justify="center" direction="vertical" gap={2}>
      <Button type={type} onClick={onClick} className="submit" loading={isLoading}>
        Start Demo
      </Button>

      <div id={ReCaptchaElement.Name} />
    </Stack>
  );
}
