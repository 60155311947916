import React, { createContext, useState, useEffect, useMemo } from 'react';

const ReCaptchaKey = {
  v2: process.env.REACT_APP_RECAPTCHA_V2_PUBLIC,
  v3: process.env.REACT_APP_RECAPTCHA_V3_PUBLIC,
};

interface IGoogleReCaptchaContext {
  executeRecaptcha: (version: 'v2' | 'v3', action: string) => Promise<string>;
  initializeV2Captcha: (cb: (token: string) => void) => void;
}

export enum ReCaptchaElement {
  Name = 'g-re-captcha',
}

function injectReCaptchaScript(onLoad: () => void, language?: string) {
  const googleRecaptchaSrc = `https://www.google.com/recaptcha/api.js?render=${ReCaptchaKey.v3}`;
  const script = document.createElement('script');
  script.src = `${googleRecaptchaSrc}${language ? `&hl=${language}` : ''}`;

  script.async = true;
  script.onload = onLoad;

  document.head.appendChild(script);
}

export const GoogleReCaptchaContext = createContext<IGoogleReCaptchaContext>({
  executeRecaptcha: () => {
    throw Error('reCaptcha not initialized');
  },
  initializeV2Captcha: () => {
    throw Error('reCaptcha not initialized');
  },
});

interface IGoogleReCaptchaProvider {
  // eslint-disable-next-line react/require-default-props
  captchaEnabled?: boolean;
  children: React.ReactNode;
}

export default function GoogleReCaptchaProvider({ children, captchaEnabled }: IGoogleReCaptchaProvider) {
  const shouldInitialize = captchaEnabled;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [instance, setInstance] = useState<unknown>(null);

  useEffect(() => {
    const onLoad = () => {
      // eslint-disable-next-line
      const { grecaptcha } = window as any;

      if (!grecaptcha) {
        throw new Error('Failed to initialize reCaptcha');
      }

      grecaptcha.ready(() => {
        setInstance(grecaptcha);
      });
    };

    if (shouldInitialize) {
      injectReCaptchaScript(onLoad);
    }
  }, [shouldInitialize]);

  const value = useMemo(() => {
    if (!instance) {
      return null;
    }

    return {
      executeRecaptcha: (version: 'v2' | 'v3', action: string): Promise<string> => {
        const siteKey = ReCaptchaKey[version];

        return (instance as any).execute(siteKey, { action });
      },
      initializeV2Captcha: (cb: (token: string) => void) => {
        (instance as any).render(ReCaptchaElement.Name, {
          sitekey: ReCaptchaKey.v2,
          callback: cb,
        });
      },
    };
  }, [instance]);

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <GoogleReCaptchaContext.Provider value={value}>{children}</GoogleReCaptchaContext.Provider>
  );
}
