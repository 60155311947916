// TODO: Replace with some API call
export interface Language {
  name: string;
  code: string;
}

const LANGUAGE_LIST: Language[] = [
  { code: 'ar', name: 'العربية' },
  { code: 'bg', name: 'Български' },
  { code: 'bn', name: 'বাংলা' },
  { code: 'ca', name: 'Català' },
  { code: 'cs', name: 'Čeština' },
  { code: 'da', name: 'Dansk' },
  { code: 'de', name: 'Deutsch' },
  { code: 'el', name: 'Ελληνικά' },
  { code: 'et', name: 'Eesti' },
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español (España)' },
  { code: 'es-latam', name: 'Español (Latinoamérica)' },
  { code: 'es-MX', name: 'Español (México)' },
  { code: 'fi', name: 'Suomi' },
  { code: 'fil', name: 'Filipino' },
  { code: 'fr', name: 'Français' },
  { code: 'hi', name: 'हिंदी' },
  { code: 'hr', name: 'Hrvatski' },
  { code: 'hu', name: 'Magyar' },
  { code: 'id', name: 'Bahasa Indonesia' },
  { code: 'it', name: 'Italiano' },
  { code: 'ja', name: '日本語' },
  { code: 'ka', name: 'ქართული' },
  { code: 'ko', name: '한국어' },
  { code: 'lv', name: 'Latviešu' },
  { code: 'lt', name: 'Lietuvių' },
  { code: 'mk', name: 'Македонски' },
  { code: 'ms', name: 'Bahasa Melayu' },
  { code: 'nb', name: 'Norsk (Bokmål)' },
  { code: 'nl', name: 'Nederlands' },
  { code: 'pl', name: 'Polski' },
  { code: 'pt', name: 'Português (Brasil)' },
  { code: 'pt-PT', name: 'Português (Portugal)' },
  { code: 'ro', name: 'Română' },
  { code: 'ru', name: 'Русский' },
  { code: 'si', name: 'සිංහල' },
  { code: 'sk', name: 'Slovenčina' },
  { code: 'sl', name: 'Slovenski' },
  { code: 'so', name: 'Af-soomaali'},
  { code: 'sr', name: 'Srpski' },
  { code: 'sv', name: 'Svenska' },
  { code: 'tr', name: 'Türkçe' },
  { code: 'th', name: 'ไทย' },
  { code: 'uk', name: 'Українська' },
  { code: 'vi', name: 'Tiếng Việt' },
  { code: 'zh', name: '简体中文' },
  { code: 'zh-Hant', name: '繁體中文' },
  { code: 'hy', name: 'հայերեն' },
];

export const getLanguages = () => Promise.resolve(LANGUAGE_LIST);
