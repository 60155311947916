import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';

import './Application.css';

import { Banner, Button, GenomaProvider, Logo, Modal } from '@veriff/genoma';
import QRCode from 'qrcode.react';
import { createVeriffFrame } from '@veriff/incontext-sdk';

import DemoForm, { DemoMode, DemoLaunchVia, FlagOverrides, DemoFormValues } from '../DemoForm/DemoForm';
import RandomQuote from '../RandomQuote/RandomQuote';

import { GoogleReCaptchaContext } from '../../utils/google-recaptcha-provider';
import { CREATE_SESSION_ERROR, createSession } from '../../api/sessions';
import { generateRedirectURL } from '../../utils/generate-redirect-url';
import { InContextEmbeddedDemo } from '../InContextEmbeddedDemo/InContextEmbeddedDemo';
import { useHeadScript } from '../../hooks/use-head-script';

const RecaptchaError = 'Please complete captcha challenge';
interface Props {
  environment: string;
  directUrl?: string;
  captchaEnabled?: boolean;
  vdUrl?: string;
}

const Application: React.FC<Props> = ({ environment, directUrl, captchaEnabled, vdUrl }) => {
  useHeadScript('https://cdn.veriff.me/incontext/js/v2_0_0_beta/veriff.js', environment);
  const [error, setError] = useState('');
  const [QRCodeURL, setQRCodeURL] = useState('');
  const [vdSessionUrl, setVdSessionUrl] = useState<string | undefined>();
  const productionMode = environment.includes('production');
  const grecaptcha = useContext(GoogleReCaptchaContext);

  const [embeddedSessionURL, setEmbeddedSessionURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (vdUrl !== undefined) {
      setVdSessionUrl(vdUrl + '/v/' + QRCodeURL.substring(QRCodeURL.lastIndexOf('/') + 1));
    }
  }, [QRCodeURL, vdUrl]);

  const handleSubmit = async (formValues: DemoFormValues, flagOverrides: FlagOverrides, v2Token?: string) => {
    try {
      const { sessionToken, integrationUrl } = await createSession(formValues, {
        captchaEnabled,
        grecaptcha,
        v2Token,
      });

      const redirectUrl = generateRedirectURL(
        integrationUrl,
        sessionToken,
        flagOverrides,
        formValues.ngrok,
        formValues.useDirectURL && directUrl,
        formValues.language,
      );

      if (formValues.mode === DemoMode.SDK) {
        return setQRCodeURL(redirectUrl);
      }

      if (formValues.launchVia === DemoLaunchVia.InContext) {
        createVeriffFrame({
          url: redirectUrl,
          onReload: () => window.location.reload(),
          lang: formValues.language,
        });
      } else if (formValues.launchVia === DemoLaunchVia.Embedded) {
        setEmbeddedSessionURL(redirectUrl);
      } else {
        window.location.href = redirectUrl;
      }

      setError('');
    } catch (error) {
      if (productionMode && error.message === CREATE_SESSION_ERROR.BOT_SUSPICIOUS_ACTIVITY) {
        setError(RecaptchaError);

        grecaptcha.initializeV2Captcha(token => {
          handleSubmit(formValues, flagOverrides, token);

          setError('');
        });
        return;
      }
      setError(error?.message ?? '');
    }
  };

  if (embeddedSessionURL) {
    return <InContextEmbeddedDemo sessionURL={embeddedSessionURL} />;
  }

  return (
    <GenomaProvider theme="light">
      <div className={cn('app-container', { 'is-production': productionMode })}>
        <div className="app-logo">
          <Logo />
        </div>
        {error && (
          <Banner variant="error" className="error-banner">
            {error}
            <Banner.Close onClick={() => setError('')} />
          </Banner>
        )}
        <DemoForm
          productionMode={productionMode}
          directUrl={directUrl}
          onSubmit={handleSubmit}
          captchaEnabled={captchaEnabled}
        />
        <Modal title="Scan QR code" open={!!QRCodeURL} onClose={() => setQRCodeURL('')}>
          {QRCodeURL && <QRCode style={{ margin: '0 auto' }} value={QRCodeURL} size={480} />}
          {vdSessionUrl && (
            <Button className="m-t" onClick={() => window.open(vdSessionUrl, '_blank')}>
              Open in VD
            </Button>
          )}
        </Modal>
        {!productionMode && <RandomQuote />}
      </div>
    </GenomaProvider>
  );
};

export default Application;
