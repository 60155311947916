import React, { useState, useRef, useEffect } from 'react';

import { PORTRAIT_WIDTH, PORTRAIT_HEIGHT } from './InContextEmbeddedDemo';

const SENSITIVITY = 20;

const isTopRightCorner = (e: React.MouseEvent<HTMLDivElement>) => {
  const rect = e.currentTarget.getBoundingClientRect();

  return e.clientX > rect.right - SENSITIVITY && e.clientY < rect.top + SENSITIVITY;
};

const isBottomRightCorner = (e: React.MouseEvent<HTMLDivElement>) => {
  const rect = e.currentTarget.getBoundingClientRect();

  return e.clientX > rect.right - SENSITIVITY && e.clientY > rect.bottom - SENSITIVITY;
};

const isRightEdge = (e: React.MouseEvent<HTMLDivElement>) => {
  const rect = e.currentTarget.getBoundingClientRect();

  return e.clientX > rect.right - SENSITIVITY;
};

const isBottomEdge = (e: React.MouseEvent<HTMLDivElement>) => {
  const rect = e.currentTarget.getBoundingClientRect();

  return e.clientY > rect.bottom - SENSITIVITY;
};

enum Resizer {
  TOP_RIGHT = 'topRight',
  BOTTOM_RIGHT = 'bottomRight',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}

interface ResizableDivProps {
  isEnabled: boolean;
  height: number | string;
  width: number | string;
  setWidth: (width: number | string) => void;
  setHeight: (height: number | string) => void;
}
const ResizableDiv = ({ isEnabled, setHeight, setWidth, width, height }: ResizableDivProps) => {
  const [dragging, setDragging] = useState(false);
  const [initialPos, setInitialPos] = useState({ x: 0, y: 0 });
  const [resizer, setResizer] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const isDisabled = !isEnabled;

  useEffect(() => {
    if (isDisabled) return;

    const handleMouseMoveWindow = (e: MouseEvent) => {
      if (!containerRef.current || !dragging) return;

      const rect = containerRef.current.getBoundingClientRect();
      const dx = e.clientX - initialPos.x;
      const dy = e.clientY - initialPos.y;
      const width = rect.width + dx;

      if (dragging) {
        switch (resizer) {
          case Resizer.TOP_RIGHT: {
            const height = rect.height - dy;
            setWidth(width);
            setHeight(height);
            containerRef.current.style.width = `${width}px`;
            containerRef.current.style.height = `${height}px`;
            break;
          }
          case Resizer.BOTTOM_RIGHT: {
            const height = rect.height + dy;
            setWidth(width);
            setHeight(height);
            containerRef.current.style.width = `${width}px`;
            containerRef.current.style.height = `${height}px`;
            break;
          }
          case Resizer.RIGHT: {
            setWidth(width);
            containerRef.current.style.width = `${width}px`;
            break;
          }

          case Resizer.BOTTOM: {
            const height = rect.height + dy;
            setHeight(height);
            containerRef.current.style.height = `${height}px`;
            break;
          }
        }

        setInitialPos({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUpWindow = () => {
      setDragging(false);
      setResizer('');
      const iframe = document.getElementById('veriffFrame');

      if (iframe) {
        iframe.style.pointerEvents = 'auto';
      }
    };

    window.addEventListener('mousemove', handleMouseMoveWindow);
    window.addEventListener('mouseup', handleMouseUpWindow);

    return () => {
      window.removeEventListener('mousemove', handleMouseMoveWindow);
      window.removeEventListener('mouseup', handleMouseUpWindow);
    };
  }, [dragging, initialPos, resizer, isDisabled, setHeight, setWidth]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDisabled) return;

    if (!containerRef.current) return;

    if (isTopRightCorner(e)) {
      containerRef.current.style.cursor = 'nesw-resize';
    } else if (isBottomRightCorner(e)) {
      containerRef.current.style.cursor = 'nwse-resize';
    } else if (isRightEdge(e)) {
      containerRef.current.style.cursor = 'ew-resize';
    } else if (isBottomEdge(e)) {
      containerRef.current.style.cursor = 'ns-resize';
    } else {
      containerRef.current.style.cursor = '';
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDisabled) return;

    if (!containerRef.current) return;

    const iframe = document.getElementById('veriffFrame');

    if (iframe) {
      iframe.style.pointerEvents = 'none';
    }

    if (isTopRightCorner(e)) {
      setDragging(true);
      setResizer(Resizer.TOP_RIGHT);
    } else if (isBottomRightCorner(e)) {
      setDragging(true);
      setResizer(Resizer.BOTTOM_RIGHT);
    } else if (isRightEdge(e)) {
      setDragging(true);
      setResizer(Resizer.RIGHT);
    } else if (isBottomEdge(e)) {
      setDragging(true);
      setResizer(Resizer.BOTTOM);
    }
    setInitialPos({ x: e.clientX, y: e.clientY });
  };

  return (
    <>
      #embedded-veriff-root
      <div
        ref={containerRef}
        id="embedded-veriff-root"
        style={{
          padding: '10px',
          width: isEnabled ? width : `${PORTRAIT_WIDTH}px`,
          height: isEnabled ? height : `${PORTRAIT_HEIGHT}px`,
        }}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
      ></div>
    </>
  );
};

export { ResizableDiv };
