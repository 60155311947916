import React from 'react';
import cn from 'classnames';
import { Checkbox, Select, Tooltip, Toggle, SelectOption, IconInfo, IconSettings } from '@veriff/genoma';
import './FlagOverrideSelector.css';

import { FeatureFlag } from '../../api/featureFlags';

interface Props {
  flag: FeatureFlag;
  value?: string;
  onSetValue: (value?: string) => void;
}

const FlagOverrideSelector: React.FC<Props> = ({
  flag: { name, options = [], description, isABTest },
  value,
  onSetValue,
}) => {
  const checked = !!value;
  const hasOptions = options.length > 0;

  const selectOptions: SelectOption[] = options.map(option => {
    if (typeof option === 'string') {
      return { label: option, value: option };
    }
    return option;
  });

  const defaultValue = hasOptions ? selectOptions[0].value : 'true';

  return (
    <div key={name} className={cn('flag-selector', { 'has-options': hasOptions })}>
      <div>
        <Checkbox
          label={name}
          checked={checked}
          onChange={() => {
            if (onSetValue) {
              onSetValue(!checked ? value || defaultValue : undefined);
            }
          }}
        />
        {description && (
          <span className="m-l">
            <Tooltip label={description}>
              <IconInfo />
            </Tooltip>
          </span>
        )}
        {isABTest && (
          <span className="m-l ld-identifier">
            <Tooltip label="AB Test flag (LaunchDarkly)">
              <IconSettings size={16} />
            </Tooltip>
          </span>
        )}
      </div>
      {hasOptions ? (
        <div>
          <Select
            disabled={!checked}
            fullWidth
            placeholder=""
            value={value}
            defaultValue={defaultValue}
            options={selectOptions}
            onChange={value => {
              onSetValue(value ?? '');
            }}
          />
        </div>
      ) : (
        <Toggle
          disabled={!checked}
          checked={value === 'true'}
          onChange={e => {
            onSetValue(e.target.checked.toString());
          }}
        />
      )}
    </div>
  );
};

export default FlagOverrideSelector;
