import { SavedCategory } from '../DemoForm/DemoForm';
import React, { useState } from 'react';
import { Button, IconCheck, IconClose, IconDelete } from '@veriff/genoma';

const FeatureFlagCategory = ({
  category,
  removeCategory,
  selectCategory,
}: {
  category: SavedCategory;
  selectCategory: (str: string) => void;
  removeCategory: (str: string) => void;
}) => {
  const [isDelete, setIsDelete] = useState(false);

  const { name } = category;
  return (
    <div className="feature-flag-category">
      <Button variant="naked" className="category-button" onClick={() => selectCategory(name)}>
        {name}
      </Button>
      {isDelete ? (
        <div className="flex">
          <Button className="delete-category" onClick={() => removeCategory(name)} variant="naked">
            <IconCheck size={24}/>
          </Button>
          <Button className="delete-category" onClick={() => setIsDelete(false)} variant="naked">
            <IconClose size={24}/>
          </Button>
        </div>
      ) : (
        <Button onClick={() => setIsDelete(true)} variant="naked">
          <IconDelete size={24}/>
        </Button>
      )}
    </div>
  );
};


export default FeatureFlagCategory;
