/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';

import { useFormik } from 'formik';
import {
  TextField,
  Select,
  Radio,
  Label,
  Autocomplete,
  Tooltip,
  Checkbox,
  Collapsible,
  Text,
  Heading,
  IconInfo,
  IconQr,
  Stack,
} from '@veriff/genoma';

import { generateRandomName } from '../../utils/generate-random-name';
import { getItem, setItem } from '../../utils/local-storage';

import { integrations } from '../../api/integrations';
import { getLanguages, Language } from '../../api/languages';
import { getCountries, Country } from '../../api/countries';
import { getFeatureFlags, FeatureFlag, FEATURE_FLAG_CATEGORIES_DEFAULT } from '../../api/featureFlags';
import FeatureFlagOverrides from '../FeatureFlagOverrides/FeatureFlagOverrides';
import VeriffMeButton from '../VeriffMeButton/VeriffMeButton';
import demoIllustration from './assets/demo-illustration.png';

import './DemoForm.css';

const INTEGRATION_LOCAL_STORAGE_KEY = 'integrationUUID';

export enum DemoLaunchVia {
  InContext = 'incontext',
  Embedded = 'incontext-embedded',
  Redirect = 'redirect',
}

export enum DemoMode {
  Browser = 'browser',
  SDK = 'sdk',
}

export interface NgrokConfig {
  enabled: boolean;
  frontBaseUrl: string;
}

export interface DemoFormValues {
  name: string;
  language?: string;
  documentCountry?: string;
  documentType?: string;
  vendorIntegration?: string;
  additionalData?: {
    isTest: boolean;
  };
  launchVia: DemoLaunchVia;
  mode: DemoMode;
  ngrok: NgrokConfig;
  useDirectURL?: boolean;
  vendorData: string;
}

export interface FlagOverrides {
  [name: string]: string | undefined;
}

export interface SavedCategory {
  name: string;
  flags: FlagOverrides;
}

interface Props {
  productionMode: boolean;
  directUrl?: string;
  onSubmit: (formValues: DemoFormValues, flagOverrides: FlagOverrides) => void;
  captchaEnabled?: boolean;
}

const MOBILE_MEDIA_QUERY = '(max-width: 767px)';

const getSavedCategoriesWithDefaults = () => {
  const finalCategories = (getItem('savedCategories') as any[]) || [];
  const finalCategoryNames = finalCategories.map(({ name }) => name);
  FEATURE_FLAG_CATEGORIES_DEFAULT.forEach(category => {
    if (!finalCategoryNames.includes(category.name)) {
      finalCategories.push(category);
    }
  });
  return finalCategories;
};

const DemoForm: React.FC<Props> = ({ productionMode, directUrl, onSubmit, captchaEnabled }) => {
  const [state, setState] = useState<{
    languages: Language[];
    countries: Country[];
    featureFlags: FeatureFlag[];
    ngrok: NgrokConfig;
  }>({
    languages: [],
    countries: [],
    featureFlags: [],
    ngrok: { enabled: false, frontBaseUrl: '' },
  });

  const initialValues = getItem<Partial<DemoFormValues>>('form');
  const isMobileMediaQuery = useRef(window.matchMedia(MOBILE_MEDIA_QUERY));
  const [isMobile, setIsMobile] = useState(isMobileMediaQuery.current.matches);

  const formik = useFormik<DemoFormValues>({
    initialValues: {
      name: generateRandomName(),
      launchVia: initialValues?.launchVia || DemoLaunchVia.InContext,
      mode: initialValues?.mode || DemoMode.Browser,
      additionalData: { isTest: false },
      ngrok: initialValues?.ngrok || { enabled: false, frontBaseUrl: '' },
      useDirectURL: initialValues?.useDirectURL || false,
      vendorData: '',
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, selectedFlags);
      setSubmitting(false);
    },
  });

  const [selectedFlags, setSelectedFlags] = useState<FlagOverrides>(getItem('selectedFlags') || {});
  const [savedCategories, setSavedCategories] = useState<SavedCategory[]>(getSavedCategoriesWithDefaults());

  const handleIntegrationChange = (value: string | null) => {
    formik.setFieldValue('vendorIntegration', value);

    setItem(INTEGRATION_LOCAL_STORAGE_KEY, value);
  };

  useEffect(() => {
    const integration = getItem(INTEGRATION_LOCAL_STORAGE_KEY);

    if (integration) {
      formik.setFieldValue('vendorIntegration', integration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on mount, causes infinite loop if added to deps
  }, []);

  useEffect(() => {
    getLanguages().then(languages => setState(state => ({ ...state, languages })));
    getCountries().then(countries => setState(state => ({ ...state, countries })));
    getFeatureFlags(productionMode).then(featureFlags => setState(state => ({ ...state, featureFlags })));
  }, [productionMode]);

  useEffect(() => {
    setItem('selectedFlags', selectedFlags);
    setItem('savedCategories', savedCategories);
  }, [selectedFlags, savedCategories]);

  useEffect(() => {
    setItem<Partial<DemoFormValues>>('form', {
      launchVia: formik.values.launchVia,
      mode: formik.values.mode,
      ngrok: formik.values.ngrok,
    });
  }, [formik.values.launchVia, formik.values.mode, formik.values.ngrok]);

  useEffect(() => {
    const onMobileChange = (evt: MediaQueryListEventMap['change']) => {
      setIsMobile(evt.matches);
    };

    const isMobileMQ = isMobileMediaQuery.current;
    if (!isMobileMQ.addEventListener) return;

    isMobileMQ.addEventListener('change', onMobileChange);

    return () => {
      isMobileMQ.removeEventListener('change', onMobileChange);
    };
  }, []);

  const documentCountryAndTypeProd = productionMode ? (
    <>
      <Autocomplete
        name="documentCountry"
        label="Document country"
        value={formik.values.documentCountry}
        onChange={value => formik.setFieldValue('documentCountry', value)}
        options={state.countries.map(l => ({ label: l.name, value: l.code }))}
        placeholder="Select a country"
        caption="Some countries may modify the session"
      />

      <Select
        name="documentType"
        label="Document type"
        value={formik.values.documentType}
        onChange={value => formik.setFieldValue('documentType', value)}
        options={[
          { label: 'Passport', value: 'PASSPORT' },
          { label: 'ID Card', value: 'ID_CARD' },
          { label: 'Residence Permit', value: 'RESIDENCE_PERMIT' },
          { label: "Driver's license", value: 'DRIVERS_LICENSE' },
        ]}
        placeholder="Select a type"
        caption="This can change the flow e.g passport"
      />
    </>
  ) : null;

  return (
    <form onSubmit={formik.handleSubmit}>
      {productionMode ? (
        <>
          <Stack gap={2} className="veriff-title-container" direction="vertical" justify="center" align="center">
            <Heading level={3}>Welcome to our Veriff Demo</Heading>
            <Text as="p" className="veriff-title-container__text">
              Veriff helps you connect with honest customers. Test out our verification flow to see how and why our
              unique user experience can increase conversion by up to 30%.
            </Text>
          </Stack>
          <img src={demoIllustration} alt="" className="demo-illustration" />
        </>
      ) : (
        <Stack paddingBottom={3}>
          <Heading level={3}>Create a demo session</Heading>
        </Stack>
      )}
      {/* {JSON.stringify(formik.values)}
      <br />
      {JSON.stringify(selectedFlags)} */}

      {productionMode && (
        <Collapsible title="Customize session" className="prod-form">
          <section className="grid-column">
            <Select
              name="language"
              label="Language"
              value={formik.values.language}
              onChange={value => formik.setFieldValue('language', value)}
              options={state.languages.map(l => ({ label: l.name, value: l.code }))}
              placeholder="Select a language"
              caption="This will change the session language"
            />

            {!isMobile ? (
              <section className="grid-double">{documentCountryAndTypeProd}</section>
            ) : (
              documentCountryAndTypeProd
            )}
          </section>
        </Collapsible>
      )}

      {!productionMode && (
        <section className="grid-panel">
          <section className="grid-double">
            <TextField
              name="name"
              label="Full Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <Select
              name="language"
              label="Session Language"
              value={formik.values.language}
              onChange={value => formik.setFieldValue('language', value)}
              options={state.languages.map(l => ({ label: l.name, value: l.code }))}
              placeholder="Select a language"
            />
          </section>

          <Autocomplete
            name="documentCountry"
            label="Document country"
            value={formik.values.documentCountry}
            onChange={value => formik.setFieldValue('documentCountry', value)}
            options={state.countries.map(l => ({ label: l.name, value: l.code }))}
            placeholder="Type a country"
          />

          <section className={productionMode ? 'grid-double-desktop' : 'display-contents'}>
            <Select
              name="documentType"
              label="Document type"
              value={formik.values.documentType}
              onChange={value => formik.setFieldValue('documentType', value)}
              options={[
                { label: 'Passport', value: 'PASSPORT' },
                { label: 'ID Card', value: 'ID_CARD' },
                { label: 'Residence Permit', value: 'RESIDENCE_PERMIT' },
                { label: "Driver's license", value: 'DRIVERS_LICENSE' },
              ]}
              placeholder="Select a document"
            />

            <Label label="Launch Veriff via (browser only)" className="flex-center">
              <div className="grid-options grid-options-launch-veriff">
                {Object.entries({
                  ...DemoLaunchVia,
                }).map(([key, value]) => (
                  <div>
                    <Radio
                      name="launchVia"
                      key={key}
                      label={key}
                      value={value}
                      defaultChecked={formik.initialValues.launchVia === value}
                      onChange={event => {
                        if (value === DemoLaunchVia.Embedded) {
                          setSelectedFlags({ ...selectedFlags, incontext_sdk_embedded_allowed: 'true' });
                        } else {
                          setSelectedFlags({ ...selectedFlags, incontext_sdk_embedded_allowed: undefined });
                        }

                        formik.handleChange(event);
                      }}
                      disabled={formik.values.mode === DemoMode.SDK}
                    />
                    {value === DemoLaunchVia.Embedded && (
                      <Tooltip label={'Embedded is still part of InContext SDK'}>
                        <IconInfo className="flex m-l" />
                      </Tooltip>
                    )}
                  </div>
                ))}
              </div>
            </Label>
          </section>

          {directUrl && (
            <div className="flex items-center">
              <Label className="min-w-max" label="Skip cluster-load-split proxy">
                <Checkbox
                  name="useDirectURL"
                  checked={formik.values.useDirectURL}
                  onChange={e => formik.setFieldValue('useDirectURL', e.target.checked)}
                />
                <Tooltip label={`Skip cluster-load-split proxy and use end-user flow directly (${directUrl}).`}>
                  <IconInfo className="flex m-l" />
                </Tooltip>
              </Label>
            </div>
          )}

          <Select
            name="vendorIntegration"
            label="Vendor Integration"
            value={formik.values.vendorIntegration}
            onChange={handleIntegrationChange}
            options={integrations.map(integration => ({ label: integration.name, value: integration.value }))}
            placeholder="Veriff Staging Demo"
          />

          <Label label="Client mode">
            <div className="grid-options">
              {Object.entries(DemoMode).map(([key, value]) => (
                <div key={key}>
                  <Radio
                    name="mode"
                    label={key}
                    value={value}
                    defaultChecked={formik.initialValues.mode === value}
                    onChange={formik.handleChange}
                  />
                  {value === DemoMode.SDK && (
                    <span className="m-l">
                      <Tooltip label="Generates QR code">
                        <IconQr />
                      </Tooltip>
                    </span>
                  )}
                </div>
              ))}
            </div>
          </Label>

          <div className="flex items-center">
            <Label className="min-w-max" label="Use ngrok">
              <Checkbox
                name="ngrok"
                checked={formik.values.ngrok.enabled}
                onChange={() =>
                  formik.setFieldValue('ngrok', {
                    ...formik.values.ngrok,
                    enabled: !formik.values.ngrok.enabled,
                  })
                }
              />
              <Tooltip label="Rewrite front base URL with ngrok proxy.">
                <IconInfo className="flex m-l" />
              </Tooltip>
            </Label>

            {formik.values.ngrok.enabled && (
              <TextField
                className="w-full m-l"
                name="frontBaseUrl"
                placeholder="Enter ngrok proxy url for front"
                value={formik.values.ngrok.frontBaseUrl}
                onChange={event => formik.setFieldValue('ngrok.frontBaseUrl', event.target.value)}
                onBlur={formik.handleBlur}
              />
            )}
          </div>
          <div className="flex items-center">
            <TextField
              name="vendorData"
              label="Vendor Data"
              value={formik.values.vendorData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </section>
      )}

      {productionMode && (
        <div className="terms-and-conditions">
          <section className="break-word m-b">
            <Text as="p">
              <b>By starting</b> this session you consent that your audio, video and technical information may be
              recorded and processed for the purposes of testing Veriff’s verification flow.
            </Text>
          </section>

          <section className="break-word m-t">
            <Text as="p">
              Read more about personal data processing in Veriff's{' '}
              <a href="https://www.veriff.com/privacy-notice">Privacy Notice.</a>
            </Text>
          </section>
        </div>
      )}

      <VeriffMeButton captchaEnabled={captchaEnabled} loading={formik.isSubmitting} submitForm={formik.submitForm} />

      {!productionMode && (
        <FeatureFlagOverrides
          featureFlags={state.featureFlags}
          selectedFlags={selectedFlags}
          setSelectedFlags={setSelectedFlags}
          savedCategories={savedCategories}
          setSavedCategories={setSavedCategories}
          formik={formik}
        />
      )}
    </form>
  );
};

export default DemoForm;
