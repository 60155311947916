import { useEffect } from 'react';

const useHeadStyle = (src: string, environment: string) => {
  const enabled = environment !== 'production';

  useEffect(() => {
    let styleElement: HTMLLinkElement | null = null;

    if (src && enabled) {
      styleElement = document.createElement('link');
      styleElement.setAttribute('rel', 'stylesheet');
      styleElement.setAttribute('href', src);
      document.head.appendChild(styleElement);
    }

    return () => {
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, [src, enabled]);
};

export { useHeadStyle };
