import axios from 'axios';

export enum CREATE_SESSION_ERROR {
  BOT_SUSPICIOUS_ACTIVITY = 'BOT_SUSPICIOUS_ACTIVITY',
}

export interface Session {
  name: string;
  language?: string;
  documentCountry?: string;
  documentType?: string;
  vendorIntegration?: string;
  additionalData?: {
    isTest: boolean;
  };
  vendorData?: string;
}

interface ICreateSessionOptions {
  captchaEnabled?: boolean,
  // eslint-disable-next-line
  grecaptcha: any;
  v2Token?: string;
}

type CreateSessionData = { sessionToken: string; integrationUrl: string };

// eslint-disable-next-line
const generateV3Captcha = (grecaptcha: any) => {
  return grecaptcha.executeRecaptcha('v3', 'submit');
}

export const createSessionWithCaptcha = async (data: Session, options: ICreateSessionOptions): Promise<CreateSessionData> => {
  try {
    const { v2Token } = options;
    let v3Token;

    if (!v2Token) {
      v3Token = await generateV3Captcha(options.grecaptcha);
    }

    const tokenData = { v2Token, v3Token };
    return await createSessionRequest(data, tokenData);
  } catch (err) {
    // Check that error is
    if (err?.response?.data?.error === CREATE_SESSION_ERROR.BOT_SUSPICIOUS_ACTIVITY) {
      throw new Error(CREATE_SESSION_ERROR.BOT_SUSPICIOUS_ACTIVITY);
    }

    throw err;
  }
};

interface ICaptchaExtension {
  v2Token?: string;
  v3Token?: string;
}

export const createSessionRequest = async (data: Session, captchaData: ICaptchaExtension = {}): Promise<CreateSessionData> => {
  const response = await axios.post<CreateSessionData>('/', {
    fullName: data.name,
    language: data.language,
    documentCountry: data.documentCountry,
    documentType: data.documentType,
    vendorIntegration: data.vendorIntegration,
    additionalData: data.additionalData,
    vendorData: data.vendorData,
    ...captchaData
  });

  return response.data;
};

export const createSession = (data: Session, options: ICreateSessionOptions) => {
  if (!options.captchaEnabled) {
    return createSessionRequest(data);
  }

  return createSessionWithCaptcha(data, options)
}
