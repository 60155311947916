import axios from "axios";

export interface Country {
    name: string;
    code: string;
}

export const getCountries = async () => {

    const response = await axios.get<Country[]>('/countries');

    return response.data;

}
