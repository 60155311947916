import React from 'react';
import ReactDOM from 'react-dom';
import GoogleReCaptchaProvider from './utils/google-recaptcha-provider';

import '@veriff/genoma/lib/style.css';

import Application from './components/Application/Application';
// import * as serviceWorker from './serviceWorker';

fetch('/environment')
  .then(response => response.json())
  .then(({ env, directUrl, captcha, vdUrl }) => {
    run(env, directUrl, captcha, vdUrl);
  })
  .catch(() => {
    run();
  });

function run(environment = '', directUrl = '', captcha = false, vdUrl = undefined) {
  ReactDOM.render(
    <GoogleReCaptchaProvider captchaEnabled={captcha}>
      <Application environment={environment} directUrl={directUrl} captchaEnabled={captcha} vdUrl={vdUrl} />
    </GoogleReCaptchaProvider>,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
