import React from 'react';
import { Text } from '@veriff/genoma';

import './RandomQuote.css';

import { quotes } from '../../utils/quotes';

const RandomQuote: React.FC = () => {
    const quote = quotes[Math.floor(Math.random() * quotes.length)];
    return (
        <div className="quote">
            <Text textStyle="small">{ quote.text }</Text>
            <div className="author">
                <Text textStyle="small">{ quote.author }</Text>
            </div>
        </div>
    );
}

export default RandomQuote;
