import axios from "axios";

export interface FeatureFlag {
  name: string;
  description?: string;
  options?: (string | { label: string; value: string })[];
  isABTest?: boolean;
}

export const FEATURE_FLAG_CATEGORIES_DEFAULT = [
  {
    name: 'Video disabled',
    flags: {
      video_enabled: 'false',
      video_required: 'false',
    },
  },
  {
    name: 'Web Landscape',
    flags: {
      video_enabled: 'false',
      video_required: 'false',
      no_country_doc_select_temp_web: 'true',
      barcode_us_ca_temp_web: 'true',
      landscape_flow_temp_web: 'enabled_fullscreen',
      portrait_document: 'true',
      landscape_sensor_flow_temp_web: 'true',
      photo_taking_centered_temp_web: 'true'
    },
  },
];



export const getFeatureFlags = async (isProduction: boolean) => {
  if (isProduction) {
    return []
  }

  const response = await axios.get<FeatureFlag[]>('/feature-flags');

  return response.data;
}
