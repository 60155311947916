import React, { useState } from 'react';
import { Button, Checkbox, Lozenge, TextField, Label } from '@veriff/genoma';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { SidebarLayout } from './Layouts/SidebarLayout';
import './InContextEmbeddedDemo.css';
import { ResizableDiv } from './ResizableDiv';

// Provitional types, should be removed once the new SDK is released.
type VeriffFrame = ReturnType<typeof createVeriffFrame>;
type VeriffFrameParams = Parameters<typeof createVeriffFrame>[0] & {
  embeddedOptions: {
    rootElementID: string;
    autoScroll: 'center' | 'end' | 'nearest' | 'start';
    modalFallbackInMobile: boolean;
  };
  embedded: boolean;
};

declare global {
  interface Window {
    veriffSDK: {
      createVeriffFrame: (params: VeriffFrameParams) => VeriffFrame;
      MESSAGES: typeof MESSAGES;
    };
  }
}

export const PORTRAIT_WIDTH = 320;
export const PORTRAIT_HEIGHT = 650;
export const LANDSCAPE_MIN_WIDTH = 600;
export const LANDSCAPE_MIN_HEIGHT = 680;
export const LANDSCAPE_OPTIMAL_WIDTH = 800;
export const LANDSCAPE_OPTIMAL_HEIGHT = 800;

enum SizeVariants {
  ACTIVE = '2px solid #00B0FF',
  INACTIVE = '1px solid #E0E0E0',
}

enum Sizes {
  PORTRAIT = 'PORTRAIT',
  MIN = 'MIN',
  OPTIMAL = 'OPTIMAL',
  SIX_THREE_FIVE = '635',
}

enum StatusVariants {
  STARTED = 'primary',
  FINISHED = 'positive',
  SUBMITTED = 'pending',
  CANCELED = 'negative',
}
const Status = ({ status }: { status: string }) => {
  let variant = StatusVariants.STARTED;

  if (status === 'FINISHED') {
    variant = StatusVariants.FINISHED;
  }
  if (status === 'SUBMITTED') {
    variant = StatusVariants.SUBMITTED;
  }
  if (status === 'CANCELED') {
    variant = StatusVariants.CANCELED;
  }

  return <Lozenge variant={variant}>{status}</Lozenge>;
};

interface Props {
  sessionURL?: string;
}
const InContextEmbeddedDemo = ({ sessionURL }: Props) => {
  const [veriffFrame, setVeriffFrame] = useState<VeriffFrame | null>(null);
  const [status, setStatus] = useState('');
  const [isConstrained, setIsConstrained] = useState(false);
  const [width, setWidth] = useState<number | string>(PORTRAIT_WIDTH);
  const [height, setHeight] = useState<number | string>(PORTRAIT_HEIGHT);
  const [modalFallbackInMobile, setModalFallbackInMobile] = useState(false);

  const handleOnStart = () => {
    if (veriffFrame) return;

    if (sessionURL) {
      const veriffFrame = window.veriffSDK.createVeriffFrame({
        url: sessionURL,
        embedded: true,
        // This API is not yet released, and can change.
        embeddedOptions: {
          rootElementID: 'embedded-veriff-root',
          autoScroll: 'center',
          modalFallbackInMobile,
        },

        onReload: () => window.location.reload(),

        onEvent: function (msg: string) {
          switch (msg) {
            case MESSAGES.STARTED:
              console.log('STARTED RECEIVED');
              setStatus('STARTED');
              break;
            case MESSAGES.FINISHED:
              console.log('FINISHED RECEIVED');
              setStatus('FINISHED');
              break;
            // @ts-ignore: Not exported by current SKD
            case MESSAGES.SUBMITTED:
              setStatus('SUBMITTED');
              console.log('SUBMITTED RECEIVED');
              break;
            case MESSAGES.CANCELED:
              console.log('CANCELED RECEIVED');
              setStatus('CANCELED');
              break;
            default:
              console.log('Unhandled message:', msg);
              break;
          }
        },
      });

      setVeriffFrame(veriffFrame);
    }
  };

  const handleReset = () => {
    if (veriffFrame) {
      veriffFrame?.close();
    }
    setVeriffFrame(null);
    setStatus('');
    setModalFallbackInMobile(false);
  };

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(parseInt(e.target.value));
  };

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const width = parseInt(e.target.value);
    const embeddedWidth = document.getElementById('embedded-veriff-root')?.offsetWidth || 0;
    const containerWidth = document.getElementById('main-content')?.offsetWidth || 0;
    const availableRoomToGrow = containerWidth - embeddedWidth;

    if (width > embeddedWidth && availableRoomToGrow <= 50) {
      return;
    } else {
      setWidth(parseInt(e.target.value));
    }
  };

  const handlePortraitSizeChange = () => {
    setWidth(PORTRAIT_WIDTH);
    setHeight(PORTRAIT_HEIGHT);
    setIsConstrained(true);
  };

  const handleMinSizeChange = () => {
    setWidth(LANDSCAPE_MIN_WIDTH);
    setHeight(LANDSCAPE_MIN_HEIGHT);
    setIsConstrained(true);
  };

  const handleSixThreeFiveSizeChange = () => {
    setWidth(635);
    setHeight(670);
    setIsConstrained(true);
  };

  const handleOptimalSizeChange = () => {
    setWidth(LANDSCAPE_OPTIMAL_WIDTH);
    setHeight(LANDSCAPE_OPTIMAL_HEIGHT);
    setIsConstrained(true);
  };

  function isSizeActive(size: Sizes) {
    if (width === PORTRAIT_WIDTH && height === PORTRAIT_HEIGHT && size === Sizes.PORTRAIT) {
      return SizeVariants.ACTIVE;
    }

    if (width === LANDSCAPE_MIN_WIDTH && height === LANDSCAPE_MIN_HEIGHT && size === Sizes.MIN) {
      return SizeVariants.ACTIVE;
    }

    if (width === LANDSCAPE_OPTIMAL_WIDTH && height === LANDSCAPE_OPTIMAL_HEIGHT && size === Sizes.OPTIMAL) {
      return SizeVariants.ACTIVE;
    }

    if (width === 635 && size === Sizes.SIX_THREE_FIVE) {
      return SizeVariants.ACTIVE;
    }

    return SizeVariants.INACTIVE;
  }

  if (!sessionURL) return null;

  return (
    <SidebarLayout>
      <div className="InContextEmbeddedModeActions">
        <Button onClick={handleOnStart}>Start Verification</Button>
        <Button onClick={handleReset}>Reset Embedded State</Button>

        <Label label="Fallback to modal in mobile">
          <Checkbox checked={modalFallbackInMobile} onChange={() => setModalFallbackInMobile(!modalFallbackInMobile)} />
        </Label>

        <Button onClick={handlePortraitSizeChange} variant="secondary" style={{ border: isSizeActive(Sizes.PORTRAIT) }}>
          Portrait (320x650)
        </Button>
        <Button onClick={handleMinSizeChange} variant="secondary" style={{ border: isSizeActive(Sizes.MIN) }}>
          Minimum (600x680)
        </Button>
        <Button
          onClick={handleSixThreeFiveSizeChange}
          variant="secondary"
          style={{ border: isSizeActive(Sizes.SIX_THREE_FIVE) }}
        >
          635 (635x670)
        </Button>
        <Button onClick={handleOptimalSizeChange} variant="secondary" style={{ border: isSizeActive(Sizes.OPTIMAL) }}>
          Optimal (starting 800x800)
        </Button>
        <Label label="Size Constrain">
          <Checkbox checked={isConstrained} onChange={() => setIsConstrained(!isConstrained)} />
        </Label>

        {isConstrained && (
          <div className="InContextEmbeddedModeActionsGridTwo">
            <Label label="width">
              <TextField name="width" value={width} onChange={handleWidthChange} type="number" />
              <input name="width" value={width} onChange={handleWidthChange} type="range" min={0} max={2000} />
            </Label>
            <Label label="height">
              <TextField name="height" value={height} onChange={handleHeightChange} type="number" />
              <input name="height" value={height} onChange={handleHeightChange} type="range" min={0} max={2000} />
            </Label>
          </div>
        )}
      </div>
      <ResizableDiv height={height} width={width} isEnabled={isConstrained} setHeight={setHeight} setWidth={setWidth} />
      {status && <Status status={status} />}
    </SidebarLayout>
  );
};

export { InContextEmbeddedDemo };
