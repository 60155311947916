import { FeatureFlag } from '../../api/featureFlags';
import { FlagOverrides } from '../DemoForm/DemoForm';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Collapsible, IconAdd, IconRemove, TextField } from '@veriff/genoma';
import FlagOverrideSelector from './FlagOverrideSelector';

const EnabledFlags = ({
  featureFlags,
  selectedFlags,
  setSelectedFlags,
  clearSelected,
  saveSelection,
  newCategoryName,
  onNewCategoryNameChanged,
}: {
  featureFlags: FeatureFlag[];
  selectedFlags: FlagOverrides;
  setSelectedFlags: Dispatch<SetStateAction<FlagOverrides>>;
  newCategoryName: string;
  clearSelected: () => void;
  saveSelection: () => void;
  onNewCategoryNameChanged: (str: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addNewOpen, setAddNewOpen] = useState(false);

  useEffect(() => {
    if (featureFlags.length > 0) setIsOpen(true);
    if (featureFlags.length === 0) setIsOpen(false);
  }, [featureFlags]);

  const handleSaveSelection = () => {
    saveSelection();
    setAddNewOpen(false);
  };

  const handleAddNew = () => {
    if (selectedFlags === {}) return;
    setAddNewOpen(true);
  };

  return (
    <div>
      <div className="enabled-flags-container">
        {addNewOpen ? (
          <div>
            <TextField
              placeholder="Name"
              value={newCategoryName}
              onChange={e => onNewCategoryNameChanged(e.target.value)}
              className="m-r"
            />
            <Button className="clear-all" onClick={handleSaveSelection}>
              Confirm
            </Button>
          </div>
        ) : (
          <Button
            className="clear-all"
            disabled={!selectedFlags || Object.keys(selectedFlags).length === 0}
            onClick={handleAddNew}
            iconBefore={<IconAdd />}
          >
            Save flags
          </Button>
        )}
        <Button
          className="clear-all"
          disabled={!selectedFlags || Object.keys(selectedFlags).length === 0}
          onClick={clearSelected}
          iconBefore={<IconRemove />}
        >
          Clear flags
        </Button>
      </div>
      <Collapsible
        title="Enabled flags"
        onToggle={isOpen => setIsOpen(isOpen)}
        open={isOpen}
        className="collapsible-flags enabled-flags"
        disabled={featureFlags.length === 0}
      >
        {featureFlags.map((flag: FeatureFlag) => (
          <FlagOverrideSelector
            key={flag.name}
            flag={flag}
            value={selectedFlags[flag.name]}
            onSetValue={value => {
              setSelectedFlags({ ...selectedFlags, [flag.name]: value });
            }}
          />
        ))}
      </Collapsible>
    </div>
  );
};

export default EnabledFlags;
